// Set site info
// var siteinfo = document.getElementsByName("siteinfo")[0];
// var site_id = siteinfo.getAttribute("data-siteid");
// var lang = siteinfo.getAttribute("data-sitelang");

var homeScrolled = false;

$(document).ready(function ()
{
	$('#go-down').click(function() {
		hideHomeVideo();
	});

	$('#nav-open').click(function(e){
		e.preventDefault();
	
		$('body').addClass('is-nav-open');
	});
	
	$('#nav-close').click(function(e){
		e.preventDefault();
	
		$('body').removeClass('is-nav-open');
	});


	/*
	 *	RICH-CONTENT
	 */

	// Lightbox
	$('.lightbox').fancybox({
		buttons: ['close']
	});
	$('.lightbox-media').fancybox({
		overlayOpacity: 0.8,
		overlayColor: 'black',
		padding: 0,
		titleShow: true,
		helpers : {
			media : {}
		}
	});

	// Youtube/Vimeo thumbs
	$(".rc-video__thumb").not('.lightbox-media').click( startVideo );
	$(".rc-video__overlay").not('.lightbox-media').click( startVideo );

	function startVideo(e) {
		var block = $(e.currentTarget);
		var iframe = $('iframe', block.parent());
		var src = iframe.attr('src');

		if (src.indexOf('autoplay=0') > -1) {
			iframe.attr('src', src.replace('autoplay=0','autoplay=1') );
		} else {
			if( src.indexOf('?') > -1 )
			{
				iframe.attr('src', src + '&autoplay=1');
			}
			else
			{
				iframe.attr('src', src + '?autoplay=1');
			}
		}

		$('.rc-video__overlay', block.parent()).fadeOut(150);
		$('.rc-video__thumb', block.parent()).fadeOut(150);
	}

	// Contentblocks slider
	$('.rc-article-slider').slick({
		adaptiveHeight: true,
		prevArrow: '<button type="button" class="slick-prev"><i class="icon-angle-left"></i></button>',
		nextArrow: '<button type="button" class="slick-next"><i class="icon-angle-right"></i></button>',
	});

	// Quote slider
	$('.rc-quote-slider__slick').slick({
		adaptiveHeight: true,
		prevArrow: '<button type="button" class="slick-prev"><i class="icon-angle-left"></i></button>',
		nextArrow: '<button type="button" class="slick-next"><i class="icon-angle-right"></i></button>',
	});

	// File upload
	bsCustomFileInput.init()
});


var didScroll = false;
var lastScroll = $(document).scrollTop();

function hideHomeVideo() {
	homeScrolled = true;

	$('body,html').animate({
		scrollTop: $('.header').position().top
	}, 1000, function () {
		$('body,html').scrollTop(0);
		lastScroll = 0;
		$('.home-intro').hide();
	});
}

function onScroll() {
	// code to execute on scroll
	var scroll_y = $(document).scrollTop();
	var offset = 0;
	
	if ($('.home-intro').length) {
		offset = $('.home-intro').outerHeight();
	}

	scroll_y = scroll_y - offset;
	
	if (scroll_y > lastScroll && scroll_y > $('.header').outerHeight()) {
		$('.header').addClass('header--hiding');
	} else {
		$('.header').removeClass('header--hiding');
	}

	// Home: first scroll down on home --> autoscroll
	if (!homeScrolled && $('.home-intro').length && lastScroll < 10 && scroll_y > lastScroll) {
		hideHomeVideo();
	}

	lastScroll = scroll_y;
}

$(window).scroll( function() {
	didScroll = true;
});

function timerTick () {	
	if (didScroll) { onScroll(); }
	didScroll = false;
}

$(window).on('load', function ()
{
	// Image slider (options in data-slick)
	$('.rc-image-slider__slick').slick();

	setInterval( timerTick, 30 );
	//didResize = true;
	didScroll = true;
	timerTick();
});



// CAPTCHA
var captcha_validated = false;

function onSubmitreCAPTCHA(response)
{
	captcha_validated = true;
	$('.rc-form form').submit();
}

function validateForm(event)
{
	event.preventDefault();
	var form = this;

	var validator = $(form).data('bs.validator');
	validator.validate();

	if (captcha_validated && !validator.hasErrors()) {
		form.submit();
	} else if(!captcha_validated) {
		grecaptcha.reset();
		grecaptcha.execute();
	}
}
